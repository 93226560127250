.pagos__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}
.pagos__container .pagos {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
}
.pagos__container .encabezado {
  display: flex;
  flex-flow: column;
  justify-content: start;
  flex-basis: 50%;
  margin-bottom: 10px;
  /* margin-left: 20px; */
  max-height: calc(100vh - 240px);
}
.pagos__tipo--comprobante {
  display: flex;
  margin-bottom: 10px;
  border-radius: 15px;
  /* width: 58.5%; */
}
.pagos__tipo--comprobante--item {
  background-color: #034b2f;
  flex-grow: 1;
  text-align: center;
  border: none;
  height: 60px;
  margin-top: 5px;
  color: white;

  transition: flex 0.5s;
}

.pagos__tipo--comprobante--item:hover {
  background-color: #01a59c;
  flex-grow: 1.2;
  transition: flex 0.5s;
  color: white;
  cursor: pointer;
}

 .pagos__tipo--comprobante--item--active {
  background-color: #01a59c;
  flex-grow: 1.2;
  color: white;
  cursor: pointer;
  text-align: center;
  border: none;
  height: 60px;
  margin-top: 5px;
  border-radius: 15px;
}

.pagos__container .pagos__methods--container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  /* width: 45%; */
  /* position:absolute; */
  bottom: 20%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 80px;
  width: 50px;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 50px;
  color: #000000;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 50px;
  color: #000000;
}

/*.pagos__container .pagos__methods--container {*/
/*  display: flex;*/
/*  margin-top: 10px;*/
/*  margin-bottom: 10px;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  !* width: 45%; *!*/
/*  !* position:absolute; *!*/
/*  bottom: 20%;*/
/*}*/

.pagos__container .pagos__methods {
  margin-right: 10px;
}

.pagos__methods .btn{
  height: 56px;
  width: 65px;
}

.btn-yape{
  background-color: #ffffff;
  border: 1px #3a2c91 solid;

}
.btn-yape:hover{
  background-color: #3a2c91;
  border: 1px #ffffff solid;
  box-shadow: #000000;
}

.btn-fpay{
  background-color: #ffffff ;
  border: 1px #d7d7d7 solid;

}
.btn-fpay:hover{
  background-color: #d7d7d7 ;
  border: 1px #ffffff solid;
  box-shadow: #000000;
}

.pagos__container .totals__container {
  display: flex;
  margin-top: 10px;
  background-color: #b2c3c0;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  flex-shrink: 0;
  /* position:absolute; */
  /* bottom:0; */
}

.pagos__container .totals__container--item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 30%;
}
.pagos__container .totals__container--item p {
  margin-right: 10px;
  margin-bottom: 0;
}

.pagos__container .totals__container--item-total {
  background-color: #01a59c;
  align-items: center;
  display: flex;
  border-radius: 15px;
}
.pagos__container .totals__container--item-total span {
  flex-basis: 40%;
  text-align: center;
  font-weight: bold;
  outline: none;
  color: white;
  border: none;
  background-color: transparent;
  font-size: 34px;
  box-shadow: 0 18px 20px 0 rgba(1, 165, 156, 0.57);
  border-radius: 15px;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}
.pagos__container .totals__container--item-total-pendiente {
  flex-grow: 1;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 15px;
}
.pagos__container .totals__container--item-total-pendiente p {
  margin-right: 5px;
  border-radius: 15px;
}

.pagos__container .info__input__container {
  display: flex;
  flex-flow: column;
  width: 100%;
  bottom: 25%;
  /* position:absolute; */
}
.pagos__container .input__container {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
}
.pagos__container .input__container label {
  margin-left: 7%;
  font-size: 16px;
}
.pagos__container .input {
  flex-basis: 70% !important;
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #a4a1a1 !important;
  font-size: 16px !important;
  margin-bottom: 5px !important;
  padding: 0px 20px !important;
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  font-weight: bold !important;
  color: #464646 !important;
  text-align: right !important;
}
.pagos__container .input:focus {
  border-bottom: 2px solid #464646 !important ;
}
.pagos__container .detalles__pago {
  margin-top: 10px;
  min-height: 100px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: auto;
  flex-grow: 1;
}

.pagos__container .detalles__pago__item {
  flex-grow: 1;
  margin-bottom: 5px;
}
.pagos__container .pagos__boton {
  width: 272.2px;
  height: 91px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 12px 24px 0 rgba(3, 75, 47, 0.6);
  background-color: #034b2f;
  font-size: 40px;
  color:white;
  border: none;
  border-radius: 15px;
}
/* .pagos__container .pagos__boton:focus{
  background-color: #01a59c;
}

.pagos__container .pagos__boton:hover{
  background-color: #01a59c;
} */
.pagos__container .preventa__container_data {
  flex-basis: 40%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  max-height: calc(100vh - 200px);
  /* margin-right: 10px; */
}
.pagos__container .preventa__cliente {
  margin-bottom: 10px;
  flex-basis: 10%;
  display: flex;
  align-items: center;
}
.pagos__container .preventa__cliente--contenido {
  display: flex;
  flex-flow: column;
}

.pagos__container .preventa__cliente p {
  margin-left: 10px;
  margin-bottom: 0px;
}

.pagos__container .preventa__carrito {
  min-height: 100px;
  overflow: auto;
  flex-grow: 1;
  /* border: solid 0.5px #464646;
  border-radius: 40px; */
}
/* .pagos__container #carrito {
}
.pagos__container .calculadora {
} */
.pagos__container .items__carrito {
  display: flex;
  flex-flow: column;
}
.pagos__container .item__carrito {
  display: flex;
}
.pagos__container .item__carrito p {
  flex-grow: 1;
  margin-left: 10px;
}

.pagos__container .titulo__item__carrito {
  display: flex;
}

.pagos__container .titulo__item__carrito p {
  flex-grow: 1;
  font-weight: bold;
}

.detalles__venta {
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin: 20px auto;
  width: 70%;
}
.detalles__venta .opciones__venta {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.detalles__venta .form-control:disabled,
.form-control[readonly] {
  background-color: white;
  padding: 4px 4px 4px 12px;
  border: 2px solid #ccc;
  border-radius: 30px;
}

#gestionPreventas input::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* #gestionPreventas{
  margin-top:20px;
} */

.no-visible{
  visibility: hidden;
}