.boton__verdeClaro {
  min-width: 62px;
  min-height: 47px;
  border-radius: 15px;
  border: none;
  background-color: #01a59c;
  color: white;
  font-size: 31px;
  padding: 0 0.5rem;
}

.boton__verdeClaro :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}

.boton__naranja {
  min-width: 62px;
  min-height: 47px;
  border-radius: 15px;
  border: none;
  background-color: #ff6600;
  color: white;
  font-size: 31px;
  padding: 0 0.5rem;
}

.boton__naranja :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}

.boton__verdeClaro--Pequeño {
  width: 42px;
  height: 35px;
  border-radius: 15px;
  border: none;
  background-color: #01a59c;
  color: white;
  font-size: 15px;
  padding: 0;
}

.boton__verdeClaro--Pequeño :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}

.boton__verdeClaro--letras {
  padding: 10px;
  min-width: 110px;
  border: none;
  background-color: #01a59c;
  color: white;
  font-size: 20px; 
  border-radius: 15px;
  height: 47px;
}

.boton__verdeClaro--letras :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}

.boton__Rojo--letras {
  padding: 10px;
  border-radius: 15px;
  min-width: 110px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 20px;
}

.boton__Rojo--letras :hover {
  opacity: 0.6;
  /* background-color: #dc3545; */
  /*color: white;*/
}

.boton__VerdeApertura--letras {
  padding: 10px;
  border-radius: 15px;
  min-width: 110px;
  border: none;
  background-color: #01a59c;
  color: white;
  font-size: 20px;
}

.boton__VerdeApertura--letras :hover {
  opacity: 0.6;
  /* background-color: #dc3545; */
  /*color: white;*/
}

.boton__Rojo {
  width: 55px;
  height: 47px;
  border-radius: 15px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 31px;
  padding: 0;
}

.boton__Rojo :hover {
  opacity: 0.6;
  /* background-color: #dc3545; */
  /*color: white;*/
}

.boton__Rojo--Pequeño {
  width: 42px;
  height: 35px;
  border-radius: 15px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 15px;
  padding: 0;
}

.boton__Rojo--Pequeño :hover {
  opacity: 0.6;
  /* background-color: #dc3545; */
  /*color: white;*/
}

.boton__verdeOscuro {
  width: 55px;
  height: 49px;
  border-radius: 15px;
  border: none;
  background-color: #034b2f;
  color: white;
  font-size: 31px;
  padding: 0;
}

.boton__verdeOscuro :hover {
  opacity: 0.6;
  /* background-color: #034B2F */
  /*color: white;*/
}
.boton__verdeOscuro--letras {
  padding: 10px;
  min-width: 110px;
  border-radius: 15px;
  border: none;
  background-color: #034b2f;
  color: white;
  font-size: 20px;
}

.boton__verdeOscuro--letras :hover {
  opacity: 0.6;
  /* background-color: #034B2F */
  /*color: white;*/
}

.boton__amarillo--letras {
  min-width: 110px;
  padding: 10px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 20px;
  border-radius: 15px;
}

.boton__Amarillo--SinLetras {
  width: 62px;
  height: 47px;
  border-radius: 15px;
  border: none;
  background-color: #ab7437;
  color: white;
  font-size: 31px;
  padding: 0;
}

.boton__Amarillo--SinLetras :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}

.boton__Negro--SinLetras {
  width: 62px;
  height: 47px;
  border-radius: 15px;
  border: none;
  background-color: #000;
  color: white;
  font-size: 31px;
  padding: 0;
}
.boton__Negro--SinLetras :hover {
  opacity: 0.6;
  /* background-color: #01A59C; */
  /*color: white;*/
}
.boton__amarillo--letras :hover {
  opacity: 0.6;
}

.boton__pequeno--y {
  min-width: 62px;
  min-height: 47px;
  
  border: none;
  background-color: #ab7437;
  color: white;
  font-size: 31px;
  padding: 0;
}

.boton__pequeno--y :hover {
  opacity: 0.6;
}

.boton__pequeno--y2 {
  width: 62px;
  height: 47px;

  border: none;
  background-color: #ab7437;
  color: white;
  font-size: 31px;
  padding: 0;
}

.boton__pequeno--y2 :hover {
  opacity: 0.6;
}

.boton__pequeno--g {
  width: 62px;
  height: 47px;

  border: none;
  background-color: #034b2f;
  color: white;
  font-size: 20px;
  padding: 0;
}

.boton__pequeno--g :hover {
  opacity: 0.6;
}

.boton__outline-pequenoPreciosList {
  width: 80px;
  height: 35px;
  border: #5082ce;
  background-color: #5082ce;
  color: white;

  

  font-size: 12px;
  cursor: pointer;
}

.boton__outline-pequenoPreciosList:hover {
  border: 1px solid #5082ce;
  background-color: white;
  color: #000000;
}

.boton__outline-pequeno--y {
  width: 50px;
  height: 35px;
  border: 1px solid #ab7437;
  background-color: white;
  color: #ab7437;
  font-size: 16px;
  cursor: pointer;
}

.boton__outline-pequeno--y:hover {
  border-color: #ab7437;
  background-color: #ab7437;
  color: white;
}

.boton__outline-pequeno--r {
  width: 50px;
  height: 35px;
  border: 1px solid #dc3545;
  background-color: white;
  color: #dc3545;
  font-size: 16px;
  cursor: pointer;
}

.boton__outline-pequeno--r:hover {
  border-color: #dc3545;
  background-color: #dc3545;
  color: white;
}



.boton__outline-pequeno--c {
  width: 50px;
  height: 35px;
  border: 1px solid #01a59c;
  background-color: white;
  color: #01a59c;
  font-size: 16px;
  cursor: pointer;
}

.boton__outline-pequeno--c:hover {
  border-color: #01a59c;
  background-color: #01a59c;
  color: white;
}

.input__lineaTablePrecios {
  flex-basis: 100% !important;
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 2px solid #a4a1a1 !important; */
  font-size: 13px !important;
  /* padding: 0px 20px !important; */
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  color: #464646 !important;
  text-align: left !important;
  width: 100%;
}

.input__lineaTablePrecios:focus {
  border-bottom: 2px solid #464646 !important ;
  box-shadow: 0 3px 2px -2px #20212447;
}

.input__linea {
  flex-basis: 70% !important;
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #a4a1a1 !important;
  font-size: 16px !important;
  /* padding: 0px 20px !important; */
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  color: #464646 !important;
  text-align: left !important;
  width: 100%;
}

.input_sin_linea {
  flex-basis: 90% !important;
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px !important;
  font-size: 16px !important;
  /* padding: 0px 20px !important; */
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  color: #464646 !important;
  text-align: left !important;
  width: 100%;
}

.input__linea:focus {
  border-bottom: 2px solid #464646 !important ;
  box-shadow: 0 3px 2px -2px #20212447;
}

.input__lineaMediana {
  
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #a4a1a1 !important;
  font-size: 16px !important;
  padding: 0px 20px !important;
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  color: #464646 !important;
  text-align: left !important;
  width: 40%;
}

.input__lineaMediana:focus {
  border-bottom: 2px solid #464646 !important ;
  box-shadow: 0 3px 2px -2px #20212447;
}


.boton__outline-maspequeno--r {
  width: 28px;
  height: 25px;
  border: 1px solid #dc3545;
  background-color: white;
  color: #dc3545;
  font-size: 14px;
  cursor: pointer;
}

.boton__outline-maspequeno--r:hover {
  border-color: #dc3545;
  background-color: #dc3545;
  color: white;
}

.boton__outline-maspequeno--y {
  width: 28px;
  height: 25px;
  border: 1px solid #ab7437;
  background-color: white;
  color: #ab7437;
  font-size: 14px;
  cursor: pointer;
}

.boton__outline-maspequeno--y:hover {
  border-color: #ab7437;
  background-color: #ab7437;
  color: white;
}

.boton__outline-maspequeno--g {
  width: 28px;
  height: 25px;
  border: 1px solid #01a59c;
  background-color: white;
  color: #01a59c;
  font-size: 14px;
  cursor: pointer;
}

.boton__outline-maspequeno--g:hover {
  border-color: #01a59c;
  background-color: #01a59c;
  color: white;
}

.boton__outline-pequeno--yConf {
  width: 50px;
  height: 35px;
  border: 1px solid #01a59c;
  background-color: white;
  color: #01a59c;
  font-size: 16px;
  cursor: pointer;
  /* margin: 6px; */
}

.boton__outline-pequeno--yConf:hover {
  border-color: #01a59c;
  background-color: #01a59c;
  color: white;
}