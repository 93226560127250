@media (max-width: 768px) {
    .mobile-max-height {
        max-height: 720px !important;
    }
}


@media (min-width: 768px) {
    .responsive-height {
        height: calc(100% - 100px) !important;
    }
}
