#usuarios_form {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 50vw;

    --margin: 1rem;
    padding: var(--margin) 0;
    margin: 0 var(--margin);
}
#usuarios_form .contraseña {
    display: flex;
    flex-wrap: wrap;
}

#usuarios_form .roles {
    overflow-y: auto;
    min-height: 110px;
}