.navbar__container {
  display: flex;
  width: 100%;
  height: 70px;
}

.navbar___logo {
  flex-basis: 20%;
  height: 70px;
  opacity: 0.92;
  box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #35605a;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navar___log--img {
  width: 210px;
  height: 100px;
  margin: 5px;
}

.menu_hamburguesa {
  width: 45px;
  height: 25px;
  color: #ffffff;
  margin: 5px;
}

.navbar___logo .link-button :hover {
  opacity: 0.4;
}

.navbar___content {
  display: flex;
  flex-basis: 80%;
  background-color: #01a59c;
  box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.16);
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  .navbar___titulo {
    display: none;
  }
  .navbar___content {
    justify-content: flex-end;
  }
}
.navbar___titulo {
  color: white;
  margin-left: 20px;
  font-size: x-large;
}

.navbar__login {
  display: flex;
  align-items: center;
  text-align: right;
  color: white;
}

/* .circulo {
  width: 60px;
  height: 60px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #777777;
}
.avatar {
  font-size: 90%;
} */

.navbar__container .link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
  padding: 15px;
}
