
.preventa__container .preventaaaa{

    justify-content: space-between;
    
    flex-grow: 1;
  }

  .almacenFormSinWidth{
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 25px; */
    align-self: center;
  }

  .almacenFormSinWidth .almacenDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
.almacenFormSinWidth .input__Almacen{
    display: flex;
    flex-basis: 90%;
    margin-left: 20px;
  }
  .almacenFormSinWidth .AlmacenBoton{
    align-self: flex-end;
    /* margin-right: 20%; */
  }

  .input__lineaaaa {
    flex-basis: 70% !important;
    background-color: transparent !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 2px solid #a4a1a1 !important;
    font-size: 16px !important;
    padding: 0px 0px !important;
    outline: none !important;
    height: 40px !important;
    border-radius: 0 !important;
    color: #464646 !important;
    text-align: left !important;
    width: 100%;
  }

  .input__lineaaaa:focus {
    border-bottom: 2px solid #464646 !important ;
    box-shadow: 0 3px 2px -2px #20212447;
  }