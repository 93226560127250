.VisorPDFModal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    width: 100%;
  }
  
.VisorPDFModal__container {
    position: absolute;
    top: 2rem;
    background-color: #f7f7f7; 
    padding: 0rem;
    box-shadow: 0 0 30px #000000;
    max-height: 85%;
    max-width: 85%;
    
    }
  
.VisorPDFModal__header {
    display: flex;
    justify-content:space-between;
    background-color: #01a59c;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

.VisorPDFModal__header-title{
    color: white;
  }
  
.VisorPDFModal__body{
    display: flex;
    justify-content:flex-end;
    overflow-y: scroll;   
    width: 100%; 
    border: 0px;
    padding: 3px;
    background-color:#f7f7f7;
    height: 100%;
  }
.VisorPDFModal__body::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
    display: none;  /* Ocultar scroll */
}
.VisorPDFModal__close-button {
    position: relative;
    float: right;
    top: 0;
    right: 0;
    border: 0;
    background-color: #F5F5F5;
    padding: 0.5rem 1rem;
    margin-bottom: 10px;
    margin-left: 10px;
    
  }
