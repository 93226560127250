.login__padre {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;

}
#login__body {
  display: flex;
  height: calc(100% - 90px);
  justify-content: center;
  align-items: center;
  min-height: 650px;
}

#login__body .login__publicidad img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
}
#login__body .login__publicidad {
  /* text-align: center;
  align-self: center; */
  display: flex;
  padding: 1rem;
  flex-basis: 50%;
  /* margin-left: 100px; */
  height: 100%;
}
#login__body .login__principal {
  display: flex;
  /* flex-direction: column; */
  /* height: 100vh; */
  justify-content: stretch;
  flex-basis: 85%;
  padding: 1rem;
}
#login__body .login__hijo__central {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
#login__body .login__hijo__central > div > img{
  height: 50vh;
  min-height: 120px;
}
#login__body .login__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border: 1px solid #35605a;
  padding: 70px;
  border-radius: 10px 10px 10px 10px;
  width: 100%; 
  align-self: center;
  box-shadow: 0 1px 6px 0 #1a1c2047;
  border-color: #dfe1e500;
  max-width: 600px;
}
#login__body .login__container i {
  font-size: 45px;
  color: #01a59c;
}

#login__body .login__container .input__linea {
  flex-basis: 70% !important;
  background-color: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 2px solid #a4a1a1 !important;
  font-size: 16px !important;
  margin-bottom: 30px !important;
  margin-top: 20px !important;
  padding: 10px 20px !important;
  outline: none !important;
  height: 40px !important;
  border-radius: 0 !important;
  color: #464646 !important;
  text-align: left !important;
  width: 100%;
  flex-grow: 4;
}

#login__body .login__container .input__linea:focus {
  border-bottom: 2px solid #464646 !important ;
  box-shadow: 0 3px 2px -2px #20212447;
}

#login__body .boton__verdeOscuro--letrasLogin {
  padding: 10px;
  min-width: 200px;
  align-self: center;
  border: none;
  background-color: #35605a;
  color: white;
  font-size: 20px;
  margin: 10px;
}

#login__body .boton__verdeOscuro--letrasLogin:hover {
  opacity: 0.9;
}

#login__footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 90px;
  padding: 0.5rem;
  padding-top: 0px;
}
.mr-1{
  font-size:x-small ;}

@media (max-width: 700px){

  #login__body .login__hijo__central > div > img{
    height: 20vh;
    min-height: 130px;}

  #login__body .login__hijo__central {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;}

  #login__body .login__container{
    padding: 15px 40px;
   
  }
  #login__body .login__principal{
    padding-top: 0px;
  }
  #login__body {
    min-height: 577px;
    height: calc(87%);
  }
  .login__padre{
    margin-top: -40px;
  }
  #login__body .login__principal {
    flex-basis: 40%;}
}

@media (max-width: 320px){
  #login__body .login__hijo__central {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    
  }
  #login__body .login__container{
    padding: 15px 40px;
   
  }
  #login__body .login__principal{
    padding-top: 0px;
    padding-right: 14px;
  }
  #login__body {
    min-height: 577px;
    height: calc(87%);
  }
  .login__padre{
    margin-top: -14px;
  }
  #login__body .login__principal {
    flex-basis: 40%;}
  }

