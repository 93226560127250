.rolpermisosmenuitem > ul > div:first-child {
    border: none !important;
}

.rolpermisosmenuitem .checkbox {
    position: relative;
}

.rolpermisosmenuitem .checkbox button {
    opacity: 0;
    z-index: 1;
    position: absolute;
    right: 42px;
    top: -15px;
    transition: all 0.2s ease-in-out 0s;
    background-color: #01a59c;
    color: white;
    margin: 1px;
}

.rolpermisosmenuitem .checkbox:hover button.show-onhover {
    opacity: 1;
}

.rolpermisosmenuitem:hover,
.rolpermisosmenuitem:hover + .rolpermisosmenuitem_children {
    background-color: rgb(235, 255, 235);
}