.new__product__container  .contenedor_modal{
    width: 800px;
}
.new__product__container input[type=text]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
    
}
.new__product__container{
    margin-top: 30px;
}