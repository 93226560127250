
.almacenForm{
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  padding-bottom: 25px;
  align-self: center;
}

.almacenForm .almacenDiv{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.caja-fondo-blanco-m-2{
  padding: 20px;
  margin-bottom: 25px !important;
  background-color: #ffffff;
  box-shadow: 4px 4px 5px 0px rgba(122,121,121,0.50);
  border: 2px solid #01A59C;
  background-color: #DBEDEC;
}
/* .almacenForm .input__Almacen{
  display: flex;
  flex-basis: 90%;
  margin-left: 20px;
} */

.almacenForm .AlmacenBoton{
  align-self: flex-end;
  /* margin-right: 20%; */
}

.almacenForm .labelValidAlmacen {
  color: red;
  font-weight: bold;
}

.buscar-text-doc::before{
  content: "Buscar Documento";
  display: inline-block;
  /* margin-left: auto !important; */
  justify-content: flex-end;
}
.proveedor-text::before{
  content: "Agregar Proveedor";
  display: inline-block;
  /* margin-left: auto !important; */
  justify-content: flex-end;
}
.buscar-text::before{
  content: "Buscar Proveedor";
  display: inline-block;
  /* margin-left: auto !important; */
  justify-content: flex-end;
}
.item-text::before{
  content: "Agregar Item";
  display: inline-block;
  /* margin-left: auto !important; */
  justify-content: flex-end;
}
.buscar-text-general::before{
  content: "Buscar";
  display: inline-block;
  /* margin-left: auto !important; */
  justify-content: flex-end;
}

/*confirm alert*/
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.react-confirm-alert-overlay {
  /*fondo*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(110, 108, 108, 0.459);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  /*botones*/
  outline: none;
  background: rgb(0, 110, 255);
  border: none;
  display: inline-block;
  padding: 15px 30px;
  color: #eee;
  margin-right: 40px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

/* nav items */
.nav-link{
  background-color: white !important;
  color: black !important;
}
.nav-link.active{
  background-color: #01A59C !important;
  color: white !important;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*confirm alert end*/

@media (max-width:530px) { 
  .tabla-almacen{
    display: block;
    overflow-x: auto;
  }

  .contenido-card{
    display: block !important;
  }
}

@media (min-width:320px) { 
    .caja-fondo-blanco-m-2{
      display: block;
      width: 100%;
    }
}

  @media (min-width:1025px) { 
    .caja-fondo-blanco-m-2{
      display: block;
      border-radius: 15px;
    }
}