.contenedorAbsoluto{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    align-self: center;
  }

  .contendorInfo{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;
  }
  .contendorInfoTablaDetalles{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 10px;
  }
  .contendorInfoTabla{
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    
    border: solid;
    border-color: black;
    border-radius:5px;
    margin: 10px
  }

  .contendorInfoDetalles{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border: solid;
    border-color: black;
    border-radius: 10%;
  }

  .contendorInfoDetallesLogo{
    display: flex;
    width: 30%;
    justify-content: flex-start;
    flex-direction: column;    
    margin: 10px
  }
  
  .Infotexto {
    display: flex;
    width: 10%;
}
.Infoselect {
    display: flex;
    width: 90%;
}

  .turnosBotones {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.turnosBusqueda {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    margin-top: 10px;
}

.turnoBusquedaItem {
    display: flex;

    width: 50%;
}

.input__lineaTurnos {
    flex-basis: 100%;
    background-color: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid #a4a1a1;
    font-size: 16px;
    padding-left: 20px;
    outline: none;
    height: 40px;
    border-radius: 0;
    color: #464646;
    text-align: left;
    width: 100%;
  }

.turnosBotonesCargarImprimir {
    display: flex;
    width: 40%;
    justify-content: flex-end;
}

.btnPDFTurnos{
    /* display: flex; */
    /* align-items: center; */
    width: 47px;
    height: 47px;
    font-size: 23px;
}

.buttonDivPDF{
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
}