.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


.cardGraphics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    border-color:  rgb(165, 165, 165) rgb(165, 165, 165)  rgb(165, 165, 165)  ; 
    border-radius: 30px;
    -webkit-box-shadow: -15px 18px 18px -6px rgba(0,0,0,0.54);
    -moz-box-shadow: -15px 18px 18px -6px rgba(0,0,0,0.54);
    box-shadow: -15px 15px 18px -6px rgba(0,0,0,0.54);
}

.dashboard__content {
  flex-basis: 20%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  padding-right: 5%;
  padding-left: 5%;
  flex-wrap: wrap;
  margin-bottom: 10%;
}

.dashboard__content--item-d {
  background-color: #034b2f;
  margin: 20px 15px;
  color: white;
  padding: 10px;
  transition: box-shadow 0.4s;
  min-height: 100px;
  max-width: 280px;
  border-radius: 15px;
}

.dashboard__content--item-d span {
  flex-grow: 2;
  color: white;
  font-size: xx-large;
  font-weight: bold;
  align-self: flex-end;
}
.dashboard__content--item-d:hover {
  box-shadow: 5px 5px #034b2f;
  border-radius: 5px;
}
.dashboard__content--item-d div {
  display: flex;
  flex-grow: 1;
  color: white;
  align-items: center;
}

.dashboard__content--item-d i {
  color: white;
  font-size: xx-large;
  margin-right: 20px;
}

.dashboard__content--item-g {
  align-items: center;
  justify-content: space-between;
  background-color: #01a59c;
  margin: 20px 15px;
  color: white;
  padding: 10px;
  min-height: 100px;
  max-width: 280px;
  border-radius: 15px;
  transition: box-shadow 0.4s;
}

.dashboard__content--item-g:hover {
  box-shadow: 5px 5px #01a59c;
  border-radius: 5px;
}

.dashboard__content--item-g > span {
  color: white;
  font-size: xx-large;
  font-weight: bold;
  align-self: flex-end;
}
.dashboard__content--item-g div {
  display: flex;
  flex-grow: 1;
  color: white;

  align-items: center;
}

.dashboard__content--item-g i {
  color: white;
  font-size: xx-large;
  margin-right: 20px;
}

.dashboard-sec-2{
  display: block;
  padding: 20px;
}

.cuadro-dashboard-1{
  background-color: white;
  border: 2px solid #01A59C;
  border-radius: 15px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 5px 5px 5px 0px rgba(133,133,133,0.75);
}

.seccion-ventas-mes{
  width: 90%;
  justify-content: space-evenly;
  margin: auto;
}

.name-data {
  color: white;
  font-weight: 700;
  text-align: center;
  padding: 8px;
  background-color: #88c9c5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid white;
}

.compras-data {
  background-color: rgb(1 165 156);
  color:white;
  border: 1px solid white;
  padding: 8px;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  font-weight: 700;
}

.compras-data-2 {
  background-color: rgb(1 165 156);
  color:white;
  border: 1px solid white;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.vales-data {
  background-color: #456d67;
  color:white;
  border: 1px solid white;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.ventas-data {
  background-color: #456d67;
  color:white;
  border: 1px solid white;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.total-data {
  background-color: rgb(3 75 47);
  color:white;
  border: 1px solid white;
  padding: 8px;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  font-weight: 700;
}
/* Dashboard Responsive*/



@media (min-width: 320px) {
  
  .dashboard__content--item-g {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
    margin-left: 0;
  }

  .dashboard__content--item-d {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
    margin-left: 0;
  }
  
}

@media (min-width: 960px) {
  
  .dashboard__content--item-g {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
  }

  .dashboard__content--item-d {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
  }
}

@media (min-width: 1025px) {
  
  .dashboard__content--item-g {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
    margin-left: 15px;
  }

  .dashboard__content--item-d {
    padding: 10px;
    min-height: 100px;
    max-width: 280px;
    margin-left: 15px;
  }

  .dashboard-sec-1{
    margin-top: 5rem;
  }
}