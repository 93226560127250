.Modal {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

/* Aparentemente nadie usa este estilo
.Modal__close-button {
  position: relative;
  float: right;
  top: 0;
  right: 0;
  border: 0;
  background-color: #F5F5F5;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  margin-left: 10px;
} */


/*********************************/
/* Usado solo por ClienteModal.js*/
/*********************************/
.Modal__container {
  position: relative;
  top: 2rem;
  background-color: white;
  /* padding: 1rem; */
  overflow-y: auto;
  max-height: calc(100vh - 65px);
  border-radius: 15px;
}
.modal-header {
  background-color: #01a59c;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.modal-header-title{
  color: white;
}
.modal__body{
  overflow-y: scroll;
}


/***************************/
/* Usado solo por Modal.js */
/***************************/
.Modal > .contenedor {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 2rem;
  background-color: white;

  --topbar-height: 3.5rem;
  border-radius: 15px 15px 15px 15px;
}
.Modal > .contenedor > .header {
  display: flex;
  justify-content: space-between;
  background-color: #01a59c;
  padding: 0.5rem 1rem;
  align-items: center;
  height: var(--topbar-height);
  border-radius: 15px 15px 0 0;
}
.Modal > .contenedor > .body {
  overflow-y: auto;
  max-height: calc(100vh - 65px - var(--topbar-height));
  height: 100%;
  background-color: #01a59d23;
}
.Modal > .contenedor > .header > label{
  font-size: 1.5rem;
  color: white;
  margin: 0;
}
.Modal > .contenedor > .header > button{
  padding: 0.5rem;
}

@media (min-width:320px) { 
  .Modal > .contenedor {
    width: 95%; 
  }
  .Modal > .contenedor > .header > label{
    font-size: 1rem;
  }
  .det-pago-letra{
    font-size: 12px;
  }
}

@media (min-width:768px) { 
  .Modal > .contenedor {
    width:40%; 
  }
  .Modal > .contenedor > .header > label{
    font-size: 1.5rem;
  }
  .det-pago-letra{
    font-size: 16px;
  }
}

@media (min-width:1024px) { 
  .Modal > .contenedor {
    width:40%; 
  }
}

.Modal2 > .contenedor {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 2rem;
  background-color: white;

  --topbar-height: 3.5rem;
  border-radius: 15px 15px 15px 15px;
}
.Modal2 > .contenedor > .header {
  display: flex;
  justify-content: space-between;
  background-color: #01a59c;
  padding: 0.5rem 1rem;
  align-items: center;
  height: var(--topbar-height);
  border-radius: 15px 15px 0 0;
}
.Modal2 > .contenedor > .body {
  overflow-y: auto;
  max-height: calc(100vh - 65px - var(--topbar-height));
  height: 100%;
  background-color: #01a59d23;
}
.Modal2 > .contenedor > .header > label{
  font-size: 1.5rem;
  color: white;
  margin: 0;
}
.Modal2 > .contenedor > .header > button{
  padding: 0.5rem;
}

@media (min-width:320px) {
  .Modal2 > .contenedor {
    width: 95%;
  }
  .Modal2 > .contenedor > .header > label{
    font-size: 1rem;
  }
  .det-pago-letra{
    font-size: 12px;
  }
}

@media (min-width:768px) {
  .Modal2 > .contenedor {
    width:40%;
  }
  .Modal2 > .contenedor > .header > label{
    font-size: 1.5rem;
  }
  .det-pago-letra{
    font-size: 16px;
  }
}

@media (min-width:1024px) {
  .Modal2 > .contenedor {
    width:40%;
  }
}
