/**************/
/* CampoInput */
/**************/

.campo_input {
  margin-bottom: 1rem;
}
.campo_input label {
  color: #464646;
  margin: 0;
}
.campo_input label:focus-within:not([readonly]) {
  color: black;
}

.campo_input input:focus:not([readonly]) {
  border-bottom: 2px solid #464646;
  box-shadow: 0 3px 2px -2px #20212447;
}

.campo_input input{
  background-color: transparent;
  border: 0 ;
  border-bottom: 2px solid #a4a1a1;
  font-size: 16px;
  padding: 0 10px;
  height: 40px;
}


/************/
/* CampoVer */
/************/

.campo_ver > div{
  background-color: transparent;
  border-bottom: 2px solid #a4a1a1;
  font-size: 16px;
  padding: 0 20px 3px 20px;
  color: #464646;
}