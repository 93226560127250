.parent_font{
  margin:0;
}
.parent {
  height: 50px;
  opacity: 0.7;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3%;
  padding-left: 3%;
}
.child {
  height: 50px;
  width: 100%;
  box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #0e66bb;
  display: flex;
  justify-content: left;
  align-items: left;
}
.child_font{
  padding-top: 7px;
  color:white;
}
.parent_icon{
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.child_icon{
  height: 30px;
  width: 30px;
  margin-left: 50px;
  margin-top: 8px;
  margin-right: 8px;
  }