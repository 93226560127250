.empleados__container_data {
  flex-basis: 47%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 60%;
  height: 100%;
  margin-left: 3%;
}

.buscadorEmpresa {
  margin-bottom: 10px;
  flex-basis: 10%;
  display: flex;
  align-items: center;
  margin-left: 3%;
}

.RadioEstadoEmpl{
  display: flex;
  justify-content:center;
}

.docEmp{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
}

.empleado__tipo--estado{
  display: flex;
  margin-bottom: 10px;
}

.empleado__tipo--estado--item{
  background-color: #034b2f;
  flex-grow: 1;
  text-align: center;
  border: none;
  height: 45px;
  margin-top: 5px;
  color: white;
}

.empleado__tipo--estado--item:hover{
  background-color: #01a59c;
  flex-grow: 3;
  transition: flex .5s;
  color: white;
  cursor: pointer;
}

.empleado__tipo--estado--item--active{
  background-color: #01a59c;
  color: white;
  flex-grow: 3;
  color: white;
  transition: flex .5s;
  cursor: pointer;
  text-align: center;
  border: none;
  height: 45px;
  margin-top: 5px;
}

.empleadosForm{
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 25px;
  align-self: center;
}

.empleadosForm .EmpleadoDocumento{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.empleadosForm .input__EmpleadoDiv {
    display: flex;
    flex-basis: 90%;
    margin-left: 20px;
}

.empleadosForm .input__EmpleadoDivFecha {
  display: flex;
  /*flex-basis: 90%;*/
  margin-left: 20px;
  width: 250px;
}

.empleadosForm .labelValidEmpleado{
    color: red;
    font-weight: bold;
}

.empleadosForm .EmpleadoBoton{
  align-self: flex-end;
  margin-right: 20%;
}


/*confirm alert*/
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {/*fondo*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(110, 108, 108, 0.459);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {/*botones*/
  outline: none;
  background: rgb(0, 110, 255);
  border: none;
  display: inline-block;
  padding: 15px 30px;
  color: #eee;
  margin-right: 40px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*confirm alert end*/
