.checkbox-toggle {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin:0;
    padding: 4px;
}

.checkbox-toggle .toggle {
    isolation: isolate;
    position: relative;
    height: 16px;
    width: 34px;
    border-radius: 15px;
    background: #b7b7b7;
    overflow: hidden;
}

.checkbox-toggle .toggle-inner {
    z-index: 2;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 14px;
    width: 32px;
    border-radius: 15px;
    overflow: hidden;
}

.checkbox-toggle input:disabled ~ .toggle-inner {
    background: #eaeaea;
}

.checkbox-toggle .active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.05s linear 0.17s;
}

.checkbox-toggle .toggle-state {
    display: none;
}

.checkbox-toggle .indicator {
    height: 100%;
    width: 200%;
    background: white;
    border-radius: 13px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.2s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

.checkbox-toggle .toggle-state:checked ~ .active-bg {
    transform: translate3d(-50%, 0, 0);
}

.checkbox-toggle .toggle-state:checked ~ .toggle-inner .indicator {
    transform: translate3d(25%, 0, 0);
}

.checkbox-toggle .toggle-state:checked:enabled ~ .active-bg {
    background: #01A59C
}
