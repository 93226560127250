#tableListMov {
  overflow:scroll;
  }

.fixed_header{
    width: 200px;
    border-collapse: collapse;
}

.fixed_header tbody{
  display:block;
  width: 107%;
  overflow: auto;
  height: 280px;
}

.fixed_header thead tr {
   display: block;
}

/**/

.fixed_header th, .fixed_header td {
padding: 10px;
text-align: left;
width: 80px;

}