#rolesForm .menu-state-width {
    width: 300px;
    flex-shrink: 0;
}

#rolesForm .permisos-width {
    width: 300px;
    flex-shrink: 0;
}

#rolesForm .menu-nombre-width {
    flex-grow: 1;
}

#rolesForm .disabled {
    color: gray;
}

#rolesForm .collapsing {
    transition: all 0.2s ease-in-out 0s;
}

#rolesForm #empresas {
    min-width: 300px;
    overflow: auto;
    max-height: calc(100vh - 100px);
}

#rolesForm #rolPermisosMenus {
    margin-top: 1rem;
}