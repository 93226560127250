.kardex__container{
    display: flex;
    flex-flow: column;
    align-items: center;
    /* height: 100%; */
}

.kardex__container .kardex {
    flex-flow: column;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
    flex-grow: 1;
  }

.kardex__container .KardexDocumento{
    display: flex;
    flex-flow: column;
    /* align-items: center; */
    height: 100%;
}
  
.kardex__container .input__KardexDivSmall {
    
    display: flex;
    flex-basis: 90%;
    margin-left: 20px;
    width: 300px;
}

.ProcesarBoton{

    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
  }

.ProcesarBotones{

    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-grow: 1;
}

.imgcomprobante{
    display: flex;
    flex-flow: column;
    align-items: center;
}