 #buscarEmpresa {
    width: 130px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 30px;
    font-size: 16px;
    background-color: white;
    background-repeat: no-repeat;
    padding: 4px 4px 4px 12px;
    -webkit-transition: width 0.8s ease-in-out;
    transition: width 0.4s ease-in-out;
  }

.bold {
    font-weight: bold;
}
  #buscarEmpresa:focus {
    width: 30%;
  }

  .empresa_input[type=text]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }

  .empresa_input[type=password]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }
  .empresa_input[type=number]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }
  .empresa_password{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
  }
  
  .empresa_input[type=date]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }

  .empresa_input[type=email]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }

  .empresa_input[type=url]{
    padding: 4px 4px 4px 12px;
    border: 2px solid #ccc;
    border-radius: 30px;
  }

  .labelValidEmpresa{
    padding: 4px 4px 4px 12px;
    color: red;
}

.cardEmpresas{
  border-radius: 20px;
}


.slow .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
  .fast .toggle-group { transition: left 0.1s; -webkit-transition: left 0.1s; }
  .quick .toggle-group { transition: none; -webkit-transition: none; }