
.detalle__pago{
    display: flex;
    border: 0px solid rgba(0,0,0,.125);
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 5px;
  }
  .detalle__pago .items__detalle__pago{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .detalle__pago .item__detalle__pago{
    margin-right: 30px;
    outline: none;
    flex-grow: 2;
    background-color: transparent !important; 
    border-top: 0px !important;
    border-left: 0px !important;
      border-right: 0px !important; 
      border-bottom: 2px solid #A4A1A1 !important;
    font-size: 16px !important;
    margin-bottom: 20px !important;
    padding: 0px 20px !important;
    outline: none !important;
    height: 50px !important;
    border-radius: 0 !important;
    color: #464646 !important;
    text-align:right !important;
    ;
  } 
  .detalle__pago .item__detalle__pago--img{
    margin-right: 20px;
    outline: none;
    height: 40px;
    width: 60px;  } 
    
    .detalle__pago .item__detalle__pago:focus{
      border-bottom: 2px solid #464646 !important ;
     
 }