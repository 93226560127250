/* .body{
    background-color: #77aa77;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2377aa77'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23cf8' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23cf8' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
} */

.np{
    padding: 0;
}

.container-formulario-fpay-modal{
    width: 100%;
    height: 647px;
    left: 698px;
    top: 122px;
}

.monto-label{
    font-size: 20px;
}

.fondo-fpay{
    background-color: #0B3933;
    display: flex;
    justify-content: center;
    width: 90%;
    align-items: center;
    align-content: center;
    height: 90%;
}

.metodo-pago{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.logo-metodo-pago{
    height: 90%;
    width: 90%;
}

.qr-fpay-metodo-pago{
    height: 75%;
    width: 60%;
}

.margen-top{
    padding-top: 55px;
}
.panel-status {
    text-transform: uppercase;
    background-color: #FFFFFF;
    color: #01A59C;
    border-radius: 10px;
    border: 4px solid #01A59C;
    width: 220px;
    height: 220px !important;
    text-align: center;
    margin: auto;
}

.panel-status-title{
    color: #000000;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.5px;
}

.panel-status-recjected-title{
    color: #ff253a;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.4px;
}

.panel-status-state{
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 0.5px;
}

.billetera-button {
    text-transform: uppercase;
    font-weight: 800;
    background-color: #01A59C;
    color: #FFFFFF;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: 22px;
    border: 2px solid #01A59C;
}

.billetera-button:hover {
    background-color: #FFFFFF;
    color: #01A59C;
    border: 2px solid #01A59C;
}

.billetera-button:focus{
    outline: 5px auto white;
}

.billetera-button:disabled{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.billetera-procesa-button{
    text-transform: uppercase;
    font-weight: 800;
    background-color: #0B3933;
    color: #FFFFFF;
    border-radius: 1px;
    letter-spacing: 1px;
    font-size: 24px;
    margin-bottom: 10px;
    border: 2px solid #0B3933;
}

.billetera-procesa-button:hover {
    background-color: #FFFFFF;
    color: #0B3933;
    border: 2px solid #0B3933;
}

.billetera-procesa-button:focus {
    outline: 5px auto white;
}

.billetera-procesa-button:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.title-end-time{
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 20px;
}

.title-rejected-time{
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 16px;
}

.title-time{
    font-weight: bold;
    color: #ff253a;
    margin-bottom: 0px;
    font-size: 20px;
}

.end-time-text{
    font-size: 12px;
}

.timer-content{
    background: white;
    padding: 5px 8px;
    border-radius: 15px;
    border: 2px solid #01A59C;
}

.margin-bot{
    margin-bottom: 50px;
}

.fpay-bg{
    background-image: url(fpaybg.png);
	background-size: cover;
	background-position: top center;
    height: 333px !important;
    width: 336px !important;
    margin: auto;
}
.bg-color{
    background-color: #84ede74d;
    padding-bottom: 1rem;
}
.bg-color-footer{
    background-color: #01a59c;
}
.no-flex{
    flex-wrap: initial !important;
}

.no-pad{
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.inline{
    display: inline-block;
    margin-left: 15px;
}

.cancelar-button{
    text-transform: uppercase;
    font-weight: 700;
    background-color: #ff253a;
    color: #FFFFFF;
    border-radius: 5px;
    letter-spacing: 1px;
    font-size: 24px;
    margin-bottom: 10px;
    border: 2px solid #ff253a;
    transition-duration: 0.4s;
    width: 200px;
}

.cancelar-button:hover {
    background-color: #FFFFFF;
    color: #ff253a;
    border: 2px solid #ff253a;
}

/* Reporte Ventas de Fpay */
.ventas-fpay-section{
    background-image: url(fpay-background.jpg);
	/* height: auto; */
	width: 100%;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.ventas-fpay-section-vh{
    background-image: url(fpay-background.jpg);
	width: 100%;
    height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.cienvh{
    height: 100vh !important;
}
.sucursal-text-color{
    color: #35605a;
}
.seccion-btn-verde-mfp{
    display: flex;
    justify-content: flex-end;
}

.btn-verde-mfp{
    background-color: #01A59C;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 22px;
    border: 2px solid #01A59C;
}

.btn-excel-mfp{
    background-color: #206b42;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 22px;
    border: 2px solid #206b42;
}

.btn-excel-mfp-letra-normal{
    background-color: #206b42;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #206b42;
}

.btn-excel-mfp:hover, .btn-excel-mfp-letra-normal:hover{
    background-color: white;
    color: #206b42;
    border: 2px solid #206b42;
}

.btn-pdf-mfp{
    background-color: #dc3545;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 22px;
    border: 2px solid #dc3545;
}

.btn-pdf-mfp-letra-normal{
    background-color: #dc3545;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #dc3545;
}

.btn-pdf-mfp:hover, .btn-pdf-mfp-letra-normal:hover{
    background-color: white;
    color: #dc3545;
    border: 2px solid #dc3545;
}

.btn-correo-mfp{
    background-color: #17a2b8;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #17a2b8;
}

.btn-correo-mfp:hover {
    background-color: white;
    color: #17a2b8;
    border: 2px solid #17a2b8;
}

.btn-reiniciar-mfp{
    background-color: #ffc107;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #ffc107;
}

.btn-reiniciar-mfp:hover {
    background-color: white;
    color: #ffc107;
    border: 2px solid #ffc107;
}

.btn-anular{
    background-color: #ff253a;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 22px;
    border: 2px solid #ff253a;
}

.btn-anular-letra-normal{
    background-color: #ff253a;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #ff253a;
}

.btn-anular:hover, .btn-anular-letra-normal:hover{
    background-color: white;
    color: #ff253a;
    border: 2px solid #ff253a;
}

.btn-verde-mfp-letra-normal{
    background-color: #01A59C;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #01A59C;
}

.btn-verde-mfp:hover, .btn-verde-mfp-letra-normal:hover{
    background-color: white;
    color: #01A59C;
    border: 2px solid #01A59C;
}

.btn-naranja-mfp{
    background-color: #ff6600;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 22px;
    border: 2px solid #ff6600;;
}

.btn-naranja-mfp-letra-normal{
    background-color: #ff6600;;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #ff6600;;
}

.btn-naranja-mfp:hover, .btn-naranja-mfp-letra-normal:hover{
    background-color: white;
    color: #ff6600;;
    border: 2px solid #ff6600;
}

.editar-text::before{
    content: "Editar";
    width: 50px;
    color: white;
}

.btn-naranja-mfp-letra-normal:hover .editar-text::before{
    color: #c44b12;
}

.procesar-text::before{
    content: "Procesar";
    width: 50px;
    color: white;
}

.btn-verde-mfp-letra-normal:hover .procesar-text::before{
    color: #01A59C;
}

.eliminar-text::before{
    content: "Eliminar";
    width: 50px;
    color: white;
}

.btn-anular-letra-normal:hover .eliminar-text::before{
    color: #ff253a;
}

.atras-text::before{
    content: "Atras";
    width: 50px;
    color: white;
}

.btn-verde-mfp:hover .atras-text::before{
    color: #01a59c;
}

.btn-pdf-mfp-letra-normal:hover{
    color: #ff253a;
}

.text-descargar::before{
    content: "Descargar Archivo";
    font-family: sans-serif;
    padding: 0px 5px; 
}
.caja-fondo-verde{
    padding: 20px;
    border: 2px solid #01a59c;
    border-radius: 15px;
    margin-bottom: 25px;
    background-color: #01a59c2e;
}

.caja-fondo-blanco-m{
    padding: 20px;
    border: gray;
    margin-bottom: 25px !important;
    background-color: #ffffff;
    box-shadow: 4px 4px 5px 0px rgba(122,121,121,0.50);
}

.pad-15-fpay{
    padding-bottom: 15px;
}

.centrado{
    display: flex;
    align-items: center;
}

.no-marg{
    margin: 0px;
}
.add-pad{
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.radio-texto{
    font-size: 18px;
    margin-left: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #466d68;
    background-color: #466d68;
}

.select-search-box__options{
    padding-left: 0px;
}

.pago-comprobante-border-l, .pago-comprobante-border-l:active{
    border-radius: 15px 0 0 15px;
}

.pago-comprobante-border-r{
    border-radius: 0 15px 15px 0;
}

.linea-fecha-fpay{
    border-bottom: 2px solid #01A59C !important;
}

.linea-fecha-fpay:focus{
    border-bottom: 2.5px solid #01A59C !important;
}

.ventas-fpay-logo{
    width: 100%;
    height: 100px;
}
.ventas-fpay-titulo{
    font-family: sans-serif;
    margin-top: 30px;
    margin-left: 21rem;
}

.letra-fecha-fpay{
    font-size: 20px;
    font-weight: bold;
}

     /*botones de la tabla react*/
     .ReactTable .-pagination .-btn {
        background-color: #01A59C;
        color: white;
    }
     .ReactTable .-pagination .-btn[disabled] {
        opacity: .5;
        cursor: default;
        background-color: #01A59C;
        color: white;
    }

.pestaña-hvr {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    }

.contenedor-fecha-fpay{
    display: flex;
    width: 40%; 
    margin-left: 30%;
}
/* botones atras y adelante del carrusel */
.carousel-control-prev-icon:after {
    content: '◄';
    font-size: 50px;
    color: #01A59C;
}

.carousel-control-next-icon:after {
    content: '►';
    font-size: 50px;
    color: #01A59C;
}

.monto-pagado{
    display: flex;
    text-align: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
}



/*Movimiento almacen*/


/* Procesar Vales inicio*/
.icon-name{
    font-size: 11px;
    text-align: center;
    color: black;
    padding-top: 5px;
    margin-bottom: 0;
}

.icon-vale{
    height: 47px;
    display: block;
    margin: auto;
    cursor: pointer;
}
/* Procesar Vales fin*/

/*React Table*/
/*desactivar desbordamiento para que pueda verse el menu scroll de los botones*/
/* .ReactTable .rt-td{
    overflow: inherit;
} */

.options-ventas{
    background-image: url(options.png);
    width: 32px;
    height: 32px;
    position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
/*React Table fin*/

/*botones*/
.ancho-boton{
    width: 42px !important;
}

.btn-tipopagos{
    height: 25px;
    padding: 0px 10px;
}

/*seccion de formalizar vales*/
.buscar-cliente-text::before{
    content: "Buscar cliente";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .buscar-cliente-text::before{
    color: #01A59C;
}

.agregar-cliente-text::before{
    content: "Agregar cliente";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .agregar-cliente-text::before{
    color: #01A59C;
}

.agregar-empleado-text::before{
    content: "Agregar Empleado";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .agregar-empleado-text::before{
    color: #01A59C;
}

.agregar-oc-text::before{
    content: "Agregar Orden de Compra";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .agregar-oc-text::before{
    color: #01A59C;
}

.registrar-text::before{
    content: "Registrar";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .registrar-text::before{
    color: #01A59C;
}

.seleccionar-cliente-text::before{
    content: "Seleccionar";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .seleccionar-cliente-text::before{
    color: #01A59C;
}

.editar-cliente-text::before{
    content: "Seleccionar";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .editar-cliente-text::before{
    color: #01A59C;
}

.preventa__tipo--comprobante--select{
    width: 90%;
    margin: auto;
    display: block;
    height: 40px;
    border: 2px solid #01A59C;
    padding: 0px 10px;
    border-radius: 5px;
}
.reporte-excel{
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: flex-end;
}

.foot{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

@media (max-width:340px) { 
    .dashboard {
        font-size: 14px;
      }
      
      .dashboardGraphicsBotons, .dashboardBotons {
        display: block;
      }
      
      .dashboard__graphicsDia, .dashboard__graphicsDiaMes {
        margin-bottom: 10px;
      }
      
      .cardGraphics {
        width: 300px;
      }
      
      .dashboard__content--item-g, .dashboard__content--item-d {
        display: block;
        margin-bottom: 10px;
      }
      
}

/*cerrar caja*/
.borde-cerrar-caja{
    border-radius: 15px;
}
#container.hidden {
    display: none;
  }

.almacen-text::before{
    content: "Registrar";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .almacen-text::before{
    color: #01A59C;
}  

.registrar-movimiento{
    width: 100%;
    border: none;
    border-radius: 15px;
    padding: 10px;
    color: white;
    font-size: 20px;
    background-color: #01A59C;
}

.aperturar-caja{
    border-radius: 15px;
    width: 40%;
    padding: 15px;
}
.instruccion-caja{
    background-color: #01A59C;
    color: white;
    padding: 20px;
}
.modal-aperturar-caja{
    width: 90%;
    margin: auto;
}
.aperturar-caja-text::before{
    content: "Aperturar";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .aperturar-caja-text::before{
    color: #01A59C;
}

.caja-fondo-blanco-modal{
    padding: 5px;
    border: gray;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 4px 4px 5px 0px rgba(122,121,121,0.50);
}
.boton-aperturar{
    padding: 15px;
    width: 70%;
    font-size: 20px;
    font-weight: 600;
}
/*cerrar caja fin*/

/*Vender*/
.borde-15{
    border-radius: 15px;
}
.primer-btn {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
.tercer-btn {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
.contenedor-muestra-clientes{
    position: relative;
}
.muestra-clientes{
    position: absolute;
    left: 30%;
    top: 20%;
}
.botones-agregar-buscar{
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    border-radius: 15px;
}  
.switch-comprobante{
    position: absolute;
    top: 11%;
    left: 2%;
}
.prodPreventaTr{
    line-height: 0.75rem;
}

/*Vender fin*/

/*Almacenes */
.nueva-preventa-text::before{
    content: "Nueva Preventa";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .nueva-preventa-text::before{
    color: #01A59C;
}
/*Almacenes fin*/

/*Empresas*/
.agregar-text::before{
    content: "Agregar";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .agregar-text::before{
    color: #01A59C;
}

.precios-text::before{
    content: "Precios";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .precios-text::before{
    color: #01A59C;
}

.vehiculos-text::before{
    content: "Vehiculos";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .vehiculos-text::before{
    color: #01A59C;
}

.conductores-text::before{
    content: "Conductores";
    width: 50px;
    color: white;
}
.btn-verde-mfp:hover .conductores-text::before{
    color: #01A59C;
}

.config-text::before{
    content: "Config.";
    width: 50px;
    color: white;
}
.btn-verde-mfp-letra-normal:hover .config-text::before{
    color: #01A59C;
}
/*Empresas fin*/

/*ordenes de compra*/
.pdf-text::before{
    content: "Descargar PDF";
    width: 50px;
    color: white;
}
.btn-pdf-mfp:hover .pdf-text::before:hover{
    color: #dc3545;
}
.btn-pdf-mfp-letra-normal:hover .pdf-text::before{
    color: #dc3545;
}

/* Cambiar el color de fondo y el ancho de la barra de desplazamiento */
::-webkit-scrollbar {
    background-color: #f2f2f2;
    width: 8px;
  }
  
  /* Cambiar el color del pulgar de la barra de desplazamiento */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
/* Reporte Ventas de Fpay vista responsive*/

/*pantalla de carga*/
/* Estilos para el spinner de carga */
.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #01a59c;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  /* Animación del spinner de carga */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Estilos para el texto dinámico */
  .texto-dinamico {
    font-size: 24px;
    font-weight: bold;
    color: #01a59c;
    margin-top: 0;
  }
  .texto-carga {
    position: relative;
    display: inline-block;
    width: 195px;
  }
  
  .texto-carga span {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation: cambio-texto 2s linear infinite;
  }
  
  .texto-carga .texto-1 {
    animation-delay: 0s;
  }
  
  .texto-carga .texto-2 {
    animation-delay: 1s;
  }
  
  @keyframes cambio-texto {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

/* Cotizaciones */
.centrado-cot{
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .custom-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .custom-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }



@media (min-width:320px)  { 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
        .navbar_titulo{
            font-size: 10px;
        }
        .logo-responsive{
            display: none;
        }
        .titulo-ventas-pay{
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .ventas-fpay-logo{
            margin-bottom: 15px;
        }
        .ventas-fpay-titulo{
            margin-left: 0;
        }
        .pagos-comprobante{
            width: 95%;
            display: block;
        }
        .pago-comprobante-border-r{
            border-radius: 0;
        }
        .pago-comprobante-border-l{
            border-radius: 0;
        }
        .contenedor-fecha-fpay{
            display: block;
            width: 90%; 
            margin: auto;
        }
        .ReactTable .-pagination{
            display: block;
        }
        .caja-fondo-blanco-m{
            display: block;
        }

        /*cliente modal inicio*/
        .Modal__container {
            width: 95%;
          }
        /*cliente modal fin*/

        /*Movimiento almacen inicio*/
        .botones-proveedor{
            margin-top: 0rem;
        }
        /*Movimiento almacen fin*/
        /* .ventas-fpay-section{
        } */
        .ventas-fpay-section-vh{
            height: auto;
        }

        .text-22px-20pxresp{
            font-size: 20px !important;
        }
        .letra14{
            font-size: 14px;
        }

        /*tabla react*/
        .drop-resp{
            display: block;
        }
        .drop-no-resp{
            display: none;
        }
        
        .ancho-prueba{
            overflow: inherit !important;
        }

        /*seccion formalizar pagos*/
        .preventa__container .encabezado{
            width: 90%;
            height: -webkit-fill-available;
            margin: auto;
        }
        .preventa__container .preventa__cliente{
            overflow: hidden;
        }
        .preventa__container .preventa{
            display: block;
            margin: auto;
        }
        .preventa__container .preventa__container_data{
            width: 95%;
            margin: auto;
        }
        #tableDetProdPreventa{
            margin-left: 0;
            width: 100%;
        }
        .preventa__container .tdDetallePreventa-resp{
            display: flex;
        }
         .preventa__container .tdDetallePreventa-desk {
            display: none;
        }
        .no-visible-resp{
            display: none;
        }

        .preventa__container .totals__container--item-total span {
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 15px;
        }

        .botones-desk{
            display: none !important;
        }
        .botones-resp{
            display: flex !important;
        }

        .oculto{
            display: none;
        }
        .obtener-reporte-resp{
            display: block;
            width: 90%;
            margin: auto;
            margin-right: 20px;
        }
        .modo-desk{
            display: none !important;
        }
        .modo-resp{
            display: block !important;
        }
        /*seccion formalizar pagos fin*/

        /*seccion caja generar pago*/
        .encabezado-resp{
            margin-top: 3rem;
        }

        .pagos__methods .btn{
            height: 65px;
            width: 76px;
        }
        .pagos__container .pagos__methods {
            margin-right: 0px;
        }

        .pagos__container .info__input__container{
            margin-bottom: 3rem;
        }
        .pagos__container .totals__container{
            display: block;
        }
        .totals__container--item-total{
            width: 20% !important;
            border-radius: 15px;
        }
        .letra-total{
            font-size: 30px !important;
        }
        .preventa__container .totals__container--item-total span{
            padding-left: 30px;
            padding-right: 30px;
            border-radius: 15px;
        }
        .boton-generar-resp{
            width: 100% !important;
        }
        .tdDetallePreventa-resp input{
            padding-right: 10px;
        }
        .total-resp{
            width: 100% !important;
            justify-content: center;
        }
        .centrar-total{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .aperturar-caja{
            width: 80%;
        }
        /*seccion caja generar pago*/

        /*cotizaciones*/
        .espacio-movil{
            margin-bottom: 5rem !important;
        }
        .caja-descripcion{
            position: absolute;
            top: 1185%;
            left: 0;
            z-index: 99;
            display: none;
        }
        .overflow-movil{
            overflow: visible !important;
        }
        .caja-ruc-movil{
            top: 155%;
        }
        .letra-movil{
            font-size: 13px !important;
        }
        /*reporte de ventas*/
        .seccion-btn-pdf-excel{
            text-align: center;
        }

     }
    @media (min-width:480px)  { 
    /* smartphones, Android phones, landscape iPhone */
    .aperturar-caja{
        width: 40%;
    } 
    }
    @media (min-width:600px)  { 
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 

    /*seccion caja generar pago*/
    .pagos__container .totals__container{
        display: flex;
    }
    .letra-total{
        font-size: 3vw !important;
    }
    /*seccion caja generar pago*/

    .cienvh-resp{
        height: 100vh !important;
    }

    }
    @media (min-width:900px) {
        /* tablet, landscape iPad, lo-res laptops ands desktops */
            .botones-desk {
                display: flex !important;
            }
    
            .botones-resp {
                display: none !important;
            }
            .pagos__methods .btn {
                height: 56px;
                width: 65px;
            }
            .aperturar-caja{
                width: 40%;
            }

            /*cotizaciones*/
            .espacio-movil{
            margin-bottom: 20px !important;
            }
            .caja-descripcion{
                width: 90%;
                position: absolute;
                top: 1030%;
                left: 117%;
                display: inherit;
            }
            .caja-ruc-movil{
                position: absolute;
                padding: 0px;
                top: 131%;
            }
            .letra-movil{
                font-size: 15px !important;
            }
            .seccion-btn-pdf-excel{
                text-align: initial;
            }
    }
    @media (min-width:1024px) { 
    /* big landscape tablets, laptops, and desktops */
        .navbar_titulo{
        font-size: 14px;
        }
        .logo-responsive{
            display: block;
        }
        .titulo-ventas-pay{
            margin-top: 5rem;
            margin-bottom: 0;
        }
        .ventas-fpay-logo{
        margin-bottom: 0;
        }
        .ventas-fpay-titulo{
        margin-left: 21rem;
        }
        .pagos-comprobante{
            width: 58.5%;
            display: flex;
        }
        .pago-comprobante-border-r{
            border-radius: 0 15px 15px 0;
        }
        .pago-comprobante-border-l{
            border-radius: 15px 0 0 15px;
        }
        .contenedor-fecha-fpay{
            display: flex;
            width: 40%; 
            margin-left: 30%;
        }
        .ReactTable .-pagination{
            display: flex;
        }
        .caja-fondo-blanco-m{
            /* padding: 0; */
            border: 0;
            border-radius: 15px;
            /* margin-bottom: 0; */
            /* background-color: initial; */
        }

        /*cliente modal inicio*/
        .Modal__container {
            width: auto;
          }
        /*cliente modal fin*/

        /*Movimiento almacen inicio*/
        .botones-proveedor{
            margin-top: 2rem;
        }
        /* .ventas-fpay-section{
            height: inherit;
        } */
        /*Movimiento almacen fin*/
        .ventas-fpay-section-vh{
            height: 100vh;

        }

        .section-modal{
          display: flex;
            justify-content: center;
        }
        .container-modal-codigo{
            height: 150px;
            width: 300px;
        }
        .text-22px-20pxresp{
            font-size: 22px !important;
        }
        .letra14{
            font-size: 22px;
        }

        /*tabla react*/
        .drop-resp{
            display: block;
        }
        .drop-no-resp{
            display: block;
        } 
        
        .ancho-prueba{
            /* flex: 350 0 auto !important; */
            width: 350px !important;
        }

        /*seccion formalizar pagos*/
        .preventa__container .encabezado{
            width: 100%;
            height: auto;
        }
        .preventa__container .preventa{
            display: flex;
        }
        .preventa__container .preventa__cliente{
            overflow: inherit;
        }
        .preventa__container .preventa__container_data{
            width: 50%;
            margin: auto;
            margin-top: 0;
        }
        #tableDetProdPreventa{
            width: 100%;
        }
        .preventa__container .tdDetallePreventa-resp{
            display: none;
        }
        .preventa__container .tdDetallePreventa-desk {
            display: flex;
        } 
        .no-visible-resp{
            display: block;
        }

        .preventa__container .totals__container--item-total span {
            padding-left: 30px;
            padding-right: 30px;
            border-radius: 15px;
        }

        .botones-desk{
            display: flex !important;
        }
        .botones-resp{
            display: none !important;
        }
        .obtener-reporte-resp{
            display: flex;
            width: 100%;
            margin: auto;
            margin-right: 0;
        }
        .modo-desk{
            display: flex !important;
        }
        .modo-resp{
            display: none !important;
        }
        /*seccion formalizar pagos fin*/

        /*seccion caja generar pago*/
        .encabezado-resp{
            margin-top: 0;
        }

        .pagos__methods .btn{
            height: 56px;
            width: 65px;
        }

        .pagos__container .pagos__methods {
            margin-right: 10px;
        }
        .pagos__container .info__input__container{
            margin-bottom: 0rem;
        }
        .totals__container--item-total{
            width: initial !important;
            border-radius: 15px;
        }
        .boton-generar-resp{
            width: initial !important;
        }
        
        /*seccion caja generar pago*/

        .cienvh-resp{
            height: auto !important;
            min-height: 100%;
        }
        
        .preventa__container .lista_productos{
            /* height: 8rem; */
        }
        #tableDetProdPreventa{
            max-height: 22rem;
        }
        .togglePreventa{
            margin-top: 2rem;
        } 
        .preventa__container{
            font-size: 12px;
        }
        .preventa__tipo--comprobante--item2{
            height: 2.5rem;
        }
        .preventa__tipo--comprobante--item--active2{
            height: 2.5rem;
        }
        .cabecera-tabla{
            font-size: 12px;
            height: 2.5rem;
        }
        .DocProductoPreventa {
            padding: 5px 4px 5px 12px;
        }  
    }

    @media (min-width:1200px) {
        .togglePreventa{
            margin-top: 2rem;
        }  
    }

    @media (min-width:1300px) {  
        .togglePreventa{
            margin-top: 2rem;
        }
        #tableDetProdPreventa{
            max-height: 24rem;
        }  
    }

    @media (min-width:1360px) {  
        .preventa__container .lista_productos{
            /* height: 16rem; */
        }
        .togglePreventa{
            margin-top: 2rem;
        }  
    }

    @media (min-width:1400px) {
        .togglePreventa{
            margin-top: 1rem;
        }
        .preventa__container{
            font-size: 16px;
        }
        #tableDetProdPreventa{
            max-height: 30rem;
        }
        .preventa__tipo--comprobante--item2{
            height: 60px;
        }
        .preventa__tipo--comprobante--item--active2{
            height: 60px;
        }
        .cabecera-tabla{
            font-size: 18px;
            height: inherit;
        }
        .DocProductoPreventa {
            padding: 10px 4px 10px 12px;
        }   
    }


    @media (min-width:1281px) { 
    /* hi-res laptops and desktops */ 
        .pagos__tipo--comprobante{
            width: 100%;
            display: flex;
        }       
    }

    @media (min-width:1600px) {
        .togglePreventa{
            margin-top: 1rem;
        }    
    }

    @media (min-width:1680px) {
        .togglePreventa{
            margin-top: 0rem;
        }
        #tableDetProdPreventa{
            max-height: 40rem;
        }     
    }

    @media (min-width:1900px) {
        .togglePreventa{
            margin-top: 0rem;
        }
        #tableDetProdPreventa{
            max-height: 40rem;
        } 
    }

      
      @media (max-width: 768px) {
        .dashboard {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 20px;
            grid-template-columns: 1fr;
          }
          
          .dashboard__graphicsBotons {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
          }
          
          .dashboard__graphicsDiaMes {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 20px;
          }
          
          .dashboardBotons {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-gap: 20px;
          }
       
      
        .dashboard__graphicsBotons {
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
      
        .dashboard__graphicsDiaMes {
          grid-template-columns: 1fr;
        }
      
        .dashboardBotons {
          grid-template-columns: 1fr;
        }
      }
